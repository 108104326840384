import styled from 'styled-components';

export const PixPaymentContainer = styled.div`
	border-top-right-radius: inherit;
	border-top-left-radius: inherit;

	.header {
		border-top-right-radius: inherit;
		border-top-left-radius: inherit;
		background-color: #edf2f7;
		padding: 0.5rem;
	}

	.title {
		display: flex;
		align-items: center;
		justify-content: center;
		width: auto;
		gap: 1rem;

		h3 {
			width: auto;
		}

		svg {
			color: #009d68;
			width: 25px !important;
			height: 25px !important;
			width: auto;
		}
	}

	.content {
		padding: 1rem;
	}
`;
