import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCheck2Circle } from 'react-icons/bs';
import { useOneClick } from '../../../contexts/OneClickBuyContext';
import { TransactionStatus } from '../../../contexts/OneClickBuyContext/types/Transaction';
import { checkPixStatus } from '../../../services/PixService';
import PixCountdown from '../Countdown/Pix';
import PixCodes from '../PixPayment/PixCodes';
import { PixPaymentContainer } from '../PixPayment/PixPaymentContainer';
import TransactionDetails from '../TransactionDetails';

const PixPayment: React.FC = () => {
	const [pixStatus, setPixStatus] = useState<TransactionStatus>(TransactionStatus.WAITING_PAYMENT);
	const { paymentResponse, redirect } = useOneClick();
	const { t } = useTranslation('upsell');

	useEffect(() => {
		if (pixStatus === TransactionStatus.AUTHORIZED) {
			redirect(paymentResponse);
			return;
		}

		let timer = setInterval(() => {
			checkPixStatus(paymentResponse.hash).then((response: TransactionStatus) => {
				setPixStatus(response);
			});
		}, 10000);

		return () => clearInterval(timer);
	}, [pixStatus]);

	useLayoutEffect(() => {
		checkPixStatus(paymentResponse.hash);
	}, []);

	return (
		<PixPaymentContainer>
			<div className='header'>
				<div className='title'>
					<BsCheck2Circle />
					<h3>{String(t('payment.pixGenerated'))}</h3>
				</div>
				<PixCountdown />
			</div>
			<div className='content'>
				<PixCodes />
				<TransactionDetails />
			</div>
		</PixPaymentContainer>
	);
};

export default PixPayment;
